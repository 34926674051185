import React from "react";
import ChatPage from "./page/Chat";
import "./App.css";


function App() {
  return (
    <div className="App">
      <div className="page-content page-container open-chat" id="page-content">
          <div className="w-100" style={{height: "100vh"}}>
              <ChatPage />
          </div>
      </div>
    </div>
  );
}
export default App;
