import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyApvQgFUg9u9cyio_Wo_O79Nn2Y0kLX_gc",
  authDomain: "hre-app.firebaseapp.com",
  projectId: "hre-app",
  storageBucket: "hre-app.appspot.com",
  messagingSenderId: "468805541416",
  appId: "1:468805541416:web:c3a92ce79cfc616c1adf1d",
  measurementId: "G-37P7P1L217"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
auth.settings.appVerificationDisabledForTesting = false;

export {auth};