import React, { useEffect, useRef, useState } from "react";

import data from "../data/data.json";
import registerData from "./../data/registerDetail.json";
import questionData from "./../data/question.json";
import Filed from "./Filed";

export default function ChatPage({ auth }) {
  const messageEndRef = useRef(null);
  const [startCount] = useState(0);
  const [EndCount, setEndCount] = useState(3);
  const [EndProfileCount, setEndProfileCount] = useState(0);
  const [EndQuestionCount, setEndQuestionCount] = useState(0);
  const [step, setStep] = useState([]);
  const [phone, setPhone] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationId, setVerificationId] = useState(null);
  const [profileUpdate, setProfileUpdate] = useState({});
  const [question, setQuestion] = useState({});
  const [pickUpType, setPickupType] = useState("");
  const [sameData, setSameData] = useState(true);
  // add Array with message
  const addArray = (message, array) => {
    const addArray =
      message !== ""
        ? {
            type: "text",
            data: {
              text: message,
            },
          }
        : array;
    setStep([...step, addArray, step[step.length - 1]]);
  };
  const endMessage = (message) => {
    const addArray = {
      type: "text",
      data: {
        text: message,
      },
    };
    setStep([...step, addArray]);
  };

  const addMessage = (message, callback) => {
    const addArray = {
      type: "text",
      data: {
        text: message,
      },
    };
    setStep([...step, addArray, step[step.length - 1]]);

    if (callback) {
      callback();
    }
  };
  // next step
  const nextStep = (jump_step) => {
    if (jump_step) {
      setStep([
        ...step,
        ...data.slice(EndCount + jump_step, EndCount + jump_step + 2),
      ]);
      setEndCount(EndCount + jump_step + 2);
      return;
    }
    setStep([...step, ...data.slice(EndCount, EndCount + 2)]);
    setEndCount(EndCount + 2);
  };
  // update user next step
  const registerDetail = (jump_step) => {
    if (jump_step) {
      setStep([
        ...step,
        ...registerData.slice(
          EndProfileCount + jump_step,
          EndProfileCount + jump_step + 2
        ),
      ]);
      setEndProfileCount(EndProfileCount + jump_step + 2);
      return;
    }
    setStep([
      ...step,
      ...registerData.slice(EndProfileCount, EndProfileCount + 2),
    ]);
    setEndProfileCount(EndProfileCount + 2);
  };

  // question next step
  const questionNext = (jump_step, text) => {
    var data = [];
    if (jump_step) {
      data = [
        ...step,
        ...questionData.slice(
          EndQuestionCount + jump_step,
          EndQuestionCount + jump_step + 2
        ),
      ];
      if (data[data.length - 1].data.key === data[data.length - 3].data.key) {
        data = [
          ...step,
          ...questionData.slice(EndQuestionCount - 4, EndQuestionCount - 2),
        ];
        jump_step = -4;
      }

      if (text) {
        const updatedMessages = [...data];
        updatedMessages[data.length - 2].data.text = text;
        data = updatedMessages;
      }
      setStep(data);
      return setEndQuestionCount(EndQuestionCount + jump_step + 2);
    }
    if (text) {
      data = [
        ...step,
        ...questionData.slice(EndQuestionCount, EndQuestionCount + 2),
      ];

      if (text) {
        const updatedMessages = [...data];
        const changedata = (updatedMessages[data.length - 2].data.text = text);
        console.log(updatedMessages);
        console.log(updatedMessages[data.length - 2]);
        data = updatedMessages;
      }
      setStep(data);
      return setEndQuestionCount(EndQuestionCount + jump_step + 2);
    }
    setStep([
      ...step,
      ...questionData.slice(EndQuestionCount, EndQuestionCount + 2),
    ]);
    setEndQuestionCount(EndQuestionCount + 2);
  };

  const profileUpdateFunction = (key, value) => {
    setProfileUpdate((prevKeyValuePairs) => ({
      ...prevKeyValuePairs,
      [key]: value,
    }));
  };

  const questionAdd = (key, value) => {
    setQuestion((prevKeyValuePairs) => ({
      ...prevKeyValuePairs,
      [key]: value,
    }));
  };

  useEffect(() => {
    setStep(data.slice(startCount, EndCount));
  }, []);

  useEffect(() => {
    messageEndRef.current.scrollIntoView();
  }, [step.length]);

  const backgroundStyle = {
    height: "100vh", // Set the height to cover the entire viewport
    overflowY: "scroll",
    height: "90vh",
  };
  return (
    <div className="card card-bordered h-100 shadow bg-body rounded">
      <div className="card-header  text-white">
        <h4 className="card-title">
          <strong>
            <img src={require("./../assets/logo.png")} width="40px" />
            HRE Personal Assistant
          </strong>
        </h4>
      </div>

      <div
        className="s-container ps-theme-default ps-active-y h-100 pt-4 pb-4"
        id="chat-content"
        style={backgroundStyle}
      >
        {step.map((item, index) => (
          <Filed
            key={index}
            itemdata={item}
            addArray={addArray}
            addMessage={addMessage}
            nextStep={nextStep}
            registerDetailNext={registerDetail}
            phone={phone}
            setPhone={setPhone}
            verificationCode={verificationCode}
            setVerificationCode={setVerificationCode}
            verificationId={verificationId}
            setVerificationId={setVerificationId}
            getProfileData={profileUpdate}
            profileUpdate={profileUpdateFunction}
            questionData={questionData}
            questionNext={questionNext}
            questionAdd={questionAdd}
            getQuestion={question}
            endMessage={endMessage}
            pickUpType={pickUpType}
            setPickUpType={setPickupType}
            sameData={sameData}
            setSameData={setSameData}
          />
        ))}
        <div ref={messageEndRef} />
      </div>
    </div>
  );
}
